import React from 'react';
import scholar from '../Media/123.png'
import ban from '../Media/ban.png'
import website from '../Media/website.png';
import mark from '../Media/mark.png';
import BusinessConsultat from '../Media/bs.png';
import viodeedit from '../Media/videoedit.png';


import "../css/Homepage1.css";
import { Link } from 'react-router-dom';
import Services from './Services';


const Homepage1 = () => {
  return (
    <>

      <div className="container">

        <img src={ban} alt="" />
        <h1 className="heading">Transforming Ideas into Impactful Campaigns – Partner with Us to Elevate Your Brand.</h1>
        {/* <div className="content">
          <img
            src={scholar}
            alt="Resume _your_career"
            className="image"
          />
      
        <div className="textContainer">
          <p className="text">
          Welcome to Do-out Creators! We're your partners in turning ideas into stunning digital experiences. With a focus on creativity and innovation, we specialize in crafting bespoke websites that stand out. Let's bring your vision to life together!
          
          </p>
          <Link to='contactpage'>
            <button className="career-guidance-button" >Contact Us</button>
          </Link>
        </div>
      </div> */}


        <div className="banner">
          <div className="banner-content">
            <h1 className="banner-heading">Website Development Services</h1>
            <p>Welcome to our website development services page. We specialize in creating stunning websites tailored to your needs, utilizing platforms like Shopify, WordPress, and Wix to bring your vision to life.</p>
            <button>Contact Us</button>
          </div>
          <div className="banner-image">
            <img src={website} alt="Website Development Banner" />
          </div>
        </div>


        <section className="service-overview">
          <div className="service-overview-banner">
            <div className="service-overview-banner-content">
              <h1 className="service-overview-banner-heading">Performance Marketing Services</h1>
              <p>Welcome to our performance marketing services page. We specialize in crafting high-impact marketing campaigns tailored to your business goals. Let us help you achieve measurable results and drive growth for your business.</p>
              <button>Contact Us</button>
            </div>
            <div className="service-overview-banner-image">
              <img src={mark} alt="Website Development Banner" />
            </div>
          </div>
        </section>
        <div>
  <Services/>
</div>

        <div className="banner">
          <div className="banner-content">
            <h1 className="banner-heading">Business Consultation Services</h1>
            <p>Business consultation involves offering expert advice and guidance to individuals or organizations seeking to improve their business performance, solve problems, or achieve specific objectives. Our team of experienced consultants works closely with clients to understand their unique needs, challenges, and goals, and then develop customized strategies and solutions to address them effectively.</p>
          <p>Whether you're a startup looking to develop a solid business plan, an established company seeking to streamline operations, or an entrepreneur aiming to launch a new product or service, our business consultation services can provide the expertise and insights you need to succeed.</p>
            <button>Contact Us</button>
          </div>
          <div className="banner-image">
            <img src={BusinessConsultat} alt="Website Development Banner" />
          </div>
        </div>


        <section className="service-overview">
          <div className="service-overview-banner">
            <div className="service-overview-banner-content">
              <h1 className="service-overview-banner-heading">Premium Video Editing Services</h1>
              <p>Welcome to our premium video editing services page. We specialize in crafting high-quality, visually stunning videos tailored to your unique needs. Our team utilizes professional editing tools and techniques to bring your vision to life with precision and excellence.</p>
              <button>Contact Us</button>
            </div>
            <div className="service-overview-banner-image">
              <img src={viodeedit} alt="Website Development Banner" />
            </div>
          </div>
        </section>

{/* 
        <div className="banner">
          <div className="banner-content">
            <h1 className="banner-heading">Website Development Services</h1>
            <p>Welcome to our website development services page. We specialize in creating stunning websites tailored to your needs, utilizing platforms like Shopify, WordPress, and Wix to bring your vision to life.</p>
            <button>Contact Us</button>
          </div>
          <div className="banner-image">
            <img src={website} alt="Website Development Banner" />
          </div>
        </div>


        <section className="service-overview">
          <div className="service-overview-banner">
            <div className="service-overview-banner-content">
              <h1 className="service-overview-banner-heading">Performance Marketing Services</h1>
              <p>Welcome to our performance marketing services page. We specialize in crafting high-impact marketing campaigns tailored to your business goals. Let us help you achieve measurable results and drive growth for your business.</p>
              <button>Contact Us</button>
            </div>
            <div className="service-overview-banner-image">
              <img src={mark} alt="Website Development Banner" />
            </div>
          </div>
        </section> */
        }





      </div>
    </>
  );
};

export default Homepage1;