import React from 'react';
import web1 from '../Media/web1.png';
import web4 from '../Media/bss.png';
import web2 from '../Media/shopify.webp';
import wordpress from '../Media/wordpress.png';
import wix from '../Media/wix.png';
import html from '../Media/html.png';
import react from '../Media/react.jpg';
import djnago from '../Media/djnago.png';
import BusinessConsultat from '../Media/bs.png';
import bs2 from '../Media/bs2.png';

import '../css/BusinessConsultation.css';


export default function BusinessConsultation() {


  // const teamMembers = [
  //   {
  //     name: 'John Doe',
  //     position: 'Lead Consultant',
  //     bio: 'John has over 10 years of experience in business consulting. He specializes in strategic planning, market analysis, and business development. John holds an MBA from XYZ University and has helped numerous clients achieve their business goals.',
  //   },
  //   {
  //     name: 'Jane Smith',
  //     position: 'Marketing Specialist',
  //     bio: 'Jane is a seasoned marketing professional with a focus on digital marketing strategies. She has worked with startups and established companies to develop and implement effective marketing campaigns. Jane holds a degree in Marketing from ABC College.',
  //   },
  //   {
  //     name: 'Michael Johnson',
  //     position: 'Financial Advisor',
  //     bio: 'Michael is a certified financial planner with expertise in financial analysis and investment strategies. He has a track record of helping clients manage their finances effectively and achieve financial stability. Michael holds a CFA designation and a Bachelor\'s degree in Finance.',
  //   }
  // ];


  return (
    <div className="business-consultation-container">


      <div className="BusinessConsultation-banner">
        <div className="BusinessConsultation-banner-content">
          <h1 className="BusinessConsultation-banner-heading">Business Consultation Services</h1>
          <p>Welcome to our business consultation services page. We specialize in providing expert guidance and strategic advice to individuals and organizations, helping them achieve their goals and overcome challenges.</p>
          <button>Contact Us</button>
        </div>
        <div className="BusinessConsultation-banner-image">
          <img src={BusinessConsultat} alt="Business Consultation Banner" />
        </div>
      </div>



      <section className="BusinessConsultation-intro">
        <div className="BusinessConsultation-intro-content">
          <h2>Introduction to Business Consultation</h2>
          <p>Business consultation involves offering expert advice and guidance to individuals or organizations seeking to improve their business performance, solve problems, or achieve specific objectives. Our team of experienced consultants works closely with clients to understand their unique needs, challenges, and goals, and then develop customized strategies and solutions to address them effectively.</p>
          <p>Whether you're a startup looking to develop a solid business plan, an established company seeking to streamline operations, or an entrepreneur aiming to launch a new product or service, our business consultation services can provide the expertise and insights you need to succeed.</p>
        </div>
      </section>





      <section className="business-consultation-platform-info">
        <h2 className="business-consultation-platform-info-title">Platform-specific Information</h2>
        <div className="business-consultation-platform-info-item">
          <div className="business-consultation-platform-info-logo">
            <img src={web2} alt="Shopify Logo" />
          </div>
          <div>
            <h3 className="business-consultation-platform-info-heading">Workflow Planning</h3>
            <p className="business-consultation-platform-info-description">Discover how our Shopify experts can create seamless e-commerce solutions tailored to your business needs. From setting up your online store to integrating payment gateways and optimizing for conversions, we've got you covered.</p>
          </div>
        </div>
        <div className="business-consultation-platform-info-item">
          <div className="business-consultation-platform-info-logo">
            <img src={wordpress} alt="WordPress Logo" />
          </div>
          <div>
            <h3 className="business-consultation-platform-info-heading">Needs Assessment</h3>
            <p className="business-consultation-platform-info-description"> Initial stage where consultants gather information about the client's requirements, challenges, and goals to understand their unique needs.</p>
          </div>
        </div>
        <div className="business-consultation-platform-info-item">
          <div className="business-consultation-platform-info-logo">
            <img src={wix} alt="Wix Logo" />
          </div>
          <div>
            <h3 className="business-consultation-platform-info-heading">Analysis and Diagnosis</h3>
            <p className="business-consultation-platform-info-description">Consultants conduct a thorough examination of the client's current processes, identifying inefficiencies, bottlenecks, and areas for improvement.</p>
          </div>
        </div>
        <div className="business-consultation-platform-info-item">
          <div className="business-consultation-platform-info-logo">
            <img src={html} alt="HTML Logo" />
          </div>
          <div>
            <h3 className="business-consultation-platform-info-heading">Strategy Development</h3>
            <p className="business-consultation-platform-info-description">Collaborative phase where consultants work with the client to devise a customized strategy tailored to address identified issues and achieve desired outcomes.</p>
          </div>
        </div>
        <div className="business-consultation-platform-info-item">
          <div className="business-consultation-platform-info-logo">
            <img src={react} alt="React Logo" />
          </div>
          <div>
            <h3 className="business-consultation-platform-info-heading">Execution and Monitoringv</h3>
            <p className="business-consultation-platform-info-description">Implementation of the devised strategy with continuous monitoring to track progress, identify any deviations, and ensure adherence to the plan.</p>
          </div>
        </div>
        <div className="business-consultation-platform-info-item">
          <div className="business-consultation-platform-info-logo">
            <img src={djnago} alt="Django Logo" />
          </div>
          <div>
            <h3 className="business-consultation-platform-info-heading">Evaluation and Optimization</h3>
            <p className="business-consultation-platform-info-description">Final stage involving evaluation of the implemented strategy's effectiveness, gathering feedback, and making necessary adjustments to optimize results and ensure sustained improvement.</p>
          </div>
        </div>
      </section>





      <section className="business-consultation-overview">
        <div className="business-consultation-overview-banner">
          <div className="business-consultation-overview-banner-content">
            <h1 className="business-consultation-overview-banner-heading">Premium Business Consultation Services</h1>
            <p>Welcome to our premium business consultation services page. We specialize in providing expert guidance and strategic advice to individuals and organizations, helping them achieve their goals and overcome challenges with precision and excellence.</p>
            <button>Contact Us</button>
          </div>
          <div className="business-consultation-overview-banner-image">
            <img src={bs2} alt="Business Consultation Banner" />
          </div>
        </div>
      </section>




      {/* <section className="business-consultation-benefits">
        <div className="business-consultation-benefits-content">
          <h2>Benefits of Business Consultation</h2>
          <p>Business consultation offers numerous advantages for individuals and organizations:</p>
          <ul>
            <li>Improved brand recognition and reputation.</li>
            <li>Increased revenue and profitability.</li>
            <li>Sustainable business growth and expansion.</li>
            <li>Enhanced operational efficiency and effectiveness.</li>
            <li>Better decision-making through informed insights.</li>
            <li>Access to specialized expertise and resources.</li>
            <li>Proactive problem-solving and risk management.</li>
            <li>Adaptability to market changes and industry trends.</li>
          </ul>
          <p>Whether you're a startup aiming for success, an established company seeking growth, or an entrepreneur with ambitious goals, our business consultation services can empower you to thrive in today's competitive landscape.</p>
        </div>
      </section> */}




<section className="business-consultation-expertise">
    <h2 className="business-consultation-expertise-title">Areas of Expertise</h2>
    <div className="business-consultation-expertise-content">
        <div className="business-consultation-expertise-item">
            <h3 className="business-consultation-expertise-heading">Brand Strategy and Development</h3>
            <p className="business-consultation-expertise-description">Crafting effective brand strategies and developing brand identities that resonate with your target audience.</p>
        </div>
        <div className="business-consultation-expertise-item">
            <h3 className="business-consultation-expertise-heading">Marketing and Advertising</h3>
            <p className="business-consultation-expertise-description">Creating comprehensive marketing and advertising campaigns to promote your products or services and reach your target market.</p>
        </div>
        <div className="business-consultation-expertise-item">
            <h3 className="business-consultation-expertise-heading">Market Research and Analysis</h3>
            <p className="business-consultation-expertise-description">Conducting in-depth market research and analysis to understand market trends, consumer behavior, and competition.</p>
        </div>
        <div className="business-consultation-expertise-item">
            <h3 className="business-consultation-expertise-heading">Business Planning and Strategy</h3>
            <p className="business-consultation-expertise-description">Developing strategic business plans and strategies to guide your company's growth and success.</p>
        </div>
        <div className="business-consultation-expertise-item">
            <h3 className="business-consultation-expertise-heading">Visual Identity and Design</h3>
            <p className="business-consultation-expertise-description">Creating visually appealing and memorable brand identities, including logos, color schemes, and design elements.</p>
        </div>
        <div className="business-consultation-expertise-item">
            <h3 className="business-consultation-expertise-heading">Digital Presence and Online Marketing</h3>
            <p className="business-consultation-expertise-description">Establishing and enhancing your digital presence through effective online marketing channels and strategies.</p>
        </div>
    </div>
</section>




<section className="business-consultation-overview">
        <div className="business-consultation-overview-banner">
          <div className="business-consultation-overview-banner-content">
            <h1 className="business-consultation-overview-banner-heading">Premium Business Consultation Services</h1>
            <p>Welcome to our premium business consultation services page. We specialize in providing expert guidance and strategic advice to individuals and organizations, helping them achieve their goals and overcome challenges with precision and excellence.</p>
            <button>Contact Us</button>
          </div>
          <div className="business-consultation-overview-banner-image">
            <img src={web1} alt="Business Consultation Banner" />
          </div>
        </div>
      </section>



<section className="business-consultation-client-testimonials">
    <h2 className="business-consultation-client-testimonials-title">Client Testimonials</h2>
    <div className="business-consultation-testimonial-container">
        <div className="business-consultation-testimonial">
            <p className="business-consultation-testimonial-text">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."</p>
            <p className="business-consultation-testimonial-author">- John Doe</p>
        </div>
        <div className="business-consultation-testimonial">
            <p className="business-consultation-testimonial-text">"Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."</p>
            <p className="business-consultation-testimonial-author">- Jane Smith</p>
        </div>
        <div className="business-consultation-testimonial">
            <p className="business-consultation-testimonial-text">"Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."</p>
            <p className="business-consultation-testimonial-author">- Michael Johnson</p>
        </div>
        {/* Add more testimonials as needed */}
    </div>
</section>




<section className="business-consultation-call-to-action">
    <div className="business-consultation-cta-container">
        <div className="business-consultation-cta-content">
            <h2>Ready to Get Started?</h2>
            <p>Contact us today for a consultation or request a quote to begin your website development journey.</p>
            <button>Contact Us</button>
        </div>
        <div className="business-consultation-cta-image">
            <img src={web4} alt="Inspiring" />
        </div>
    </div>
</section>





    </div>
  )
}
