
import '../css/Services.css'; // Import CSS for styling
import React from 'react';
import service6Image from '../Media/123.png';
import marketing from '../Media/marketing.png';
import website from '../Media/website.png';
import consultant from '../Media/consultant.png';
import videoss from '../Media/videoss.png';
import graphics from '../Media/graphics.png';
import leadss from '../Media/leadss.png';
import { Link } from 'react-router-dom';

const Services = () => {
  return (
    <div className="services-container">
      {/* Top Section */}
      <div className="top-section">
        <div className="left-section">
          <h2>Welcome to Our Services</h2>
        </div>
        <div className="right-section">
          <img src={website} alt="Service 1" />
          {/* <img src={service6Image} alt="Service 2" />
          <img src={service6Image} alt="Service 3" /> */}
        </div>
      </div>

      {/* Service Cards Section */}
      <div className="service-cards">
        {/* Service Card 1 */}
        <div className="service-card">
          <img src={website} alt="Service 1" />
          <h3>Website Development</h3>
          <p>Description of Service 1To reduce the gap between the heading and paragraph in the service cards, you can adjust the margin-bottom property for the </p>
          <Link to="/WebsiteDevelopment"><button>View More</button></Link>
        </div>

        {/* Service Card 2 */}
        <div className="service-card">
          <img src={marketing} alt="Service 2" />
          <h3>Performance Marketing</h3>
          <p>Description of Service 2 To reduce the gap between the heading and paragraph in the service cards, you can adjust the margin-bottom property for the </p>
          <Link to="/performance_marketing"><button>View More</button></Link>
        </div>

        <div className="service-card">
          <img src={consultant} alt="Service 2" />
          <h3>Business Consultation</h3>
          <p>Description of Service 2To reduce the gap between the heading and paragraph in the service cards, you can adjust the margin-bottom property for the </p>
          <Link to="/business_consultation">
            <button>View More</button>
          </Link>
        </div>

        <div className="service-card">
          <img src={videoss} alt="Service 3" />
          <h3>Video Editing</h3>
          <p>Description of Service 2To reduce the gap between the heading and paragraph in the service cards, you can adjust the margin-bottom property for the </p>
          <Link to="/video_editing"><button>View More</button></Link>
        </div>

        <div className="service-card">
          <img src={graphics} alt="Service 2" />
          <h3>Graphics Designing</h3>
          <p>Description of Service 2To reduce the gap between the heading and paragraph in the service cards, you can adjust the margin-bottom property for the </p>
          <Link to="/graphics_design"><button>View More</button></Link>
        </div>

        <div className="service-card">
          <img src={leadss} alt="Service 2" />
          <h3>Lead Generation</h3>
          <p>Description of Service 2To reduce the gap between the heading and paragraph in the service cards, you can adjust the margin-bottom property for the </p>
          <Link to="/lead_generation"><button>View More</button></Link>
        </div>


      </div>
    </div>
  );
};

export default Services;