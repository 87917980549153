import React from 'react';
import web1 from '../Media/web1.png';
import web4 from '../Media/web4.png';
import web2 from '../Media/shopify.webp';
import wordpress from '../Media/wordpress.png';
import wix from '../Media/wix.png';
import html from '../Media/html.png';
import react from '../Media/react.jpg';
import djnago from '../Media/djnago.png';
import web3 from '../Media/web3.png';
import '../css/GraphicsDesign.css';



export default function GraphicsDesign() {
    return (
        <div className="graphics-design-container">

            <div className="graphics-designing-banner">
                <div className="graphics-designing-banner-content">
                    <h1 className="graphics-designing-banner-heading">Graphics Designing Services</h1>
                    <p>Welcome to our graphics designing services page. We specialize in creating stunning visual designs tailored to your needs, utilizing industry-standard tools and techniques to bring your vision to life.</p>
                    <button>Contact Us</button>
                </div>
                <div className="graphics-designing-banner-image">
                    <img src={web1} alt="Graphics Designing Banner" />
                </div>
            </div>



            {/* <section className="graphics-designing-platform-info">
                <h2 className="graphics-designing-platform-info-title">Platform-specific Information</h2>
                <div className="graphics-designing-platform-info-item">
                    <div className="graphics-designing-platform-info-logo">
                        <img src={web2} alt="Shopify Logo" />
                    </div>
                    <div>
                        <h3 className="graphics-designing-platform-info-heading">Shopify</h3>
                        <p className="graphics-designing-platform-info-description">Discover how our Shopify experts can create seamless e-commerce solutions tailored to your business needs. From setting up your online store to integrating payment gateways and optimizing for conversions, we've got you covered.</p>
                    </div>
                </div>
                <div className="graphics-designing-platform-info-item">
                    <div className="graphics-designing-platform-info-logo">
                        <img src={wordpress} alt="WordPress Logo" />
                    </div>
                    <div>
                        <h3 className="graphics-designing-platform-info-heading">WordPress</h3>
                        <p className="graphics-designing-platform-info-description">Explore the endless possibilities of WordPress with our experienced team. Whether you need a simple blog or a complex corporate website, we can design and develop a WordPress solution that meets your requirements and exceeds your expectations.</p>
                    </div>
                </div>
                <div className="graphics-designing-platform-info-item">
                    <div className="graphics-designing-platform-info-logo">
                        <img src={wix} alt="Wix Logo" />
                    </div>
                    <div>
                        <h3 className="graphics-designing-platform-info-heading">Wix</h3>
                        <p className="graphics-designing-platform-info-description">Unlock the full potential of Wix with our customization and development services. We can transform your Wix website into a professional and engaging online presence, utilizing Wix's intuitive platform to showcase your brand and captivate your audience.</p>
                    </div>
                </div>
                <div className="graphics-designing-platform-info-item">
                    <div className="graphics-designing-platform-info-logo">
                        <img src={html} alt="HTML Logo" />
                    </div>
                    <div>
                        <h3 className="graphics-designing-platform-info-heading">HTML</h3>
                        <p className="graphics-designing-platform-info-description">Harness the power of HTML to build sleek and responsive websites from scratch. Our HTML experts ensure clean, semantic code and pixel-perfect designs, resulting in websites that load fast and look great on any device.</p>
                    </div>
                </div>
                <div className="graphics-designing-platform-info-item">
                    <div className="graphics-designing-platform-info-logo">
                        <img src={react} alt="React Logo" />
                    </div>
                    <div>
                        <h3 className="graphics-designing-platform-info-heading">React</h3>
                        <p className="graphics-designing-platform-info-description">Experience the latest in front-end development with React. Our React developers create dynamic and interactive user interfaces that enhance user experience and streamline navigation. Let us elevate your web presence with the power of React.</p>
                    </div>
                </div>
                <div className="graphics-designing-platform-info-item">
                    <div className="graphics-designing-platform-info-logo">
                        <img src={djnago} alt="Django Logo" />
                    </div>
                    <div>
                        <h3 className="graphics-designing-platform-info-heading">Django</h3>
                        <p className="graphics-designing-platform-info-description">Empower your web applications with Django, the high-level Python web framework. Our Django developers build robust and scalable web solutions, leveraging Django's built-in features for authentication, security, and database management.</p>
                    </div>
                </div>
            </section> */}



            <section className="graphics-designing-service-overview">
                <div className="graphics-designing-service-overview-banner">
                    <div className="graphics-designing-service-overview-banner-content">
                        <h1 className="graphics-designing-service-overview-banner-heading">Premium Graphics Designing Services</h1>
                        <p>Welcome to our premium graphics designing services page. We specialize in crafting high-quality, visually stunning designs tailored to your unique needs. Our team utilizes industry-standard tools and techniques to bring your vision to life with precision and excellence.</p>
                        <button>Contact Us</button>
                    </div>
                    <div className="graphics-designing-service-overview-banner-image">
                        <img src={web3} alt="Graphics Designing Banner" />
                    </div>
                </div>
            </section>




            <section className="graphics-designing-expertise">
                <h2 className="graphics-designing-expertise-title">Expertise and Experience</h2>
                <div className="graphics-designing-expertise-content">
                    <div className="graphics-designing-expertise-item">
                        <h3 className="graphics-designing-expertise-heading">Custom Graphics Design</h3>
                        <p className="graphics-designing-expertise-description">We specialize in crafting custom graphics tailored to your unique business needs. Our experienced team of designers ensures that your graphics stand out with their originality and creativity.</p>
                    </div>
                    <div className="graphics-designing-expertise-item">
                        <h3 className="graphics-designing-expertise-heading">Visual Branding Solutions</h3>
                        <p className="graphics-designing-expertise-description">With expertise in branding strategies, we create visual branding solutions that resonate with your target audience and enhance your brand identity. From logo design to brand guidelines, we've got you covered.</p>
                    </div>
                    <div className="graphics-designing-expertise-item">
                        <h3 className="graphics-designing-expertise-heading">Responsive Design</h3>
                        <p className="graphics-designing-expertise-description">We prioritize responsive design to ensure your graphics look and function flawlessly across all platforms and devices. From desktops to mobile devices, your audience will enjoy a consistent and engaging visual experience.</p>
                    </div>
                    <div className="graphics-designing-expertise-item">
                        <h3 className="graphics-designing-expertise-heading">Illustration and Artwork</h3>
                        <p className="graphics-designing-expertise-description">Our team excels in illustration and artwork creation, delivering captivating visuals that bring your ideas to life. Whether you need illustrations for websites, marketing materials, or print media, we can fulfill your artistic needs.</p>
                    </div>
                    {/* <div className="graphics-designing-expertise-item">
                        <h3 className="graphics-designing-expertise-heading">Print Design</h3>
                        <p className="graphics-designing-expertise-description">We offer comprehensive print design services, including brochure design, flyer design, business card design, and more. Our print designs are crafted with precision and attention to detail, ensuring high-quality results that leave a lasting impression.</p>
                    </div> */}
                </div>
            </section>


            <section className="graphics-designing-call-to-action">
                <div className="graphics-designing-cta-container">
                    <div className="graphics-designing-cta-content">
                        <h2>Ready to Get Started?</h2>
                        <p>Contact us today for a consultation or request a quote to begin your graphics designing journey.</p>
                        <button>Contact Us</button>
                    </div>
                    <div className="graphics-designing-cta-image">
                        <img src={web4} alt="Inspiring" />
                    </div>
                </div>
            </section>


            <section className="graphics-designing-client-testimonials">
                <h2 className="graphics-designing-client-testimonials-title">Client Testimonials</h2>
                <div className="graphics-designing-testimonial-container">
                    <div className="graphics-designing-testimonial">
                        <p className="graphics-designing-testimonial-text">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."</p>
                        <p className="graphics-designing-testimonial-author">- John Doe</p>
                    </div>
                    <div className="graphics-designing-testimonial">
                        <p className="graphics-designing-testimonial-text">"Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."</p>
                        <p className="graphics-designing-testimonial-author">- Jane Smith</p>
                    </div>
                    <div className="graphics-designing-testimonial">
                        <p className="graphics-designing-testimonial-text">"Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."</p>
                        <p className="graphics-designing-testimonial-author">- Michael Johnson</p>
                    </div>
                    <div className="graphics-designing-testimonial">
                        <p className="graphics-designing-testimonial-text">"Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."</p>
                        <p className="graphics-designing-testimonial-author">- Michael Johnson</p>
                    </div>
                    {/* Add more testimonials as needed */}
                </div>
            </section>




        </div>
    )
}
