import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faHome, faInfoCircle, faEnvelope, faUsers, faWrench  } from '@fortawesome/free-solid-svg-icons';
import '../css/Navbar.css';
import logo from '../Media/dout.jpeg'

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [activePage, setActivePage] = useState('/'); // Set default active page to Home

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handlePageChange = (page) => {
    setActivePage(page);
    setShowMenu(false); // Close the menu after selecting an option
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={logo} alt="logo" />
      </div>
      <div className={`navbar-links ${showMenu ? 'show' : ''}`}>
        <ul>
          <li className={activePage === '/' ? 'active' : ''}>
            <Link to="/" onClick={() => handlePageChange('/')}>
              <FontAwesomeIcon icon={faHome} />
              <span>Home</span>
            </Link>
          </li>
          <li className={activePage === '/services' ? 'active' : ''}>
            <Link to="/services" onClick={() => handlePageChange('/services')}>
              <FontAwesomeIcon icon={faWrench } />
              <span>Services</span>
            </Link>
          </li>
          <li className={activePage === '/about' ? 'active' : ''}>
            <Link to="/about" onClick={() => handlePageChange('/about')}>
              <FontAwesomeIcon icon={faInfoCircle} />
              <span>About</span>
            </Link>
          </li>
          <li className={activePage === '/contact' ? 'active' : ''}>
            <Link to="/contactpage" onClick={() => handlePageChange('/contactpage')}>
              <FontAwesomeIcon icon={faEnvelope} />
              <span>Contact</span>
            </Link>
          </li>
          {/* <li className={activePage === '/blog' ? 'active' : ''}>
            <Link to="/blog" onClick={() => handlePageChange('/blog')}>
              <FontAwesomeIcon icon={faBlog} />
              <span>Blog</span>
            </Link>
          </li> */}
          {/* <li className={activePage === '/gallery' ? 'active' : ''}>
            <Link to="/gallery" onClick={() => handlePageChange('/gallery')}>
              <FontAwesomeIcon icon={faImages} />
              <span>Gallery</span>
            </Link>
          </li> */}
          <li className={activePage === '/team' ? 'active' : ''}>
            <Link to="/team_members" onClick={() => handlePageChange('/teteam_membersam')}>
              <FontAwesomeIcon icon={faUsers} />
              <span>Team</span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="navbar-toggle" onClick={toggleMenu}>
        <FontAwesomeIcon icon={faBars} />
      </div>
    </nav>
  );
};

export default Navbar;
