import React from 'react';
import web1 from '../Media/web1.png';
import web4 from '../Media/web4.png';
import web2 from '../Media/shopify.webp';
import wordpress from '../Media/wordpress.png';
import wix from '../Media/wix.png';
import html from '../Media/html.png';
import react from '../Media/react.jpg';
import djnago from '../Media/djnago.png';
import web3 from '../Media/web3.png';
import videoss from '../Media/videoss.png';

import '../css/VideoEditing.css';



export default function VideoEditing() {
    return (
        <div className="video-editing-container">


            <div className="VideoEditing-banner">
                <div className="VideoEditing-banner-content">
                    <h1 className="VideoEditing-banner-heading">Video Editing Services</h1>
                    <p>Welcome to our video editing services page. We specialize in creating captivating videos tailored to your needs, utilizing professional editing tools and techniques to bring your vision to life.</p>
                    <button>Contact Us</button>
                </div>
                <div className="VideoEditing-banner-image">
                    <img src={videoss} alt="Video Editing Banner" />
                </div>
            </div>


{/* 
            <section className="VideoEditing-platform-info">
                <h2 className="VideoEditing-platform-info-title">Platform-specific Information for Video Editing</h2>
                <div className="VideoEditing-platform-info-item">
                    <div className="VideoEditing-platform-info-logo">
                        <img src={web2} alt="Shopify Logo" />
                    </div>
                    <div>
                        <h3 className="VideoEditing-platform-info-heading">Shopify</h3>
                        <p className="VideoEditing-platform-info-description">Discover how our Shopify experts can create seamless e-commerce solutions tailored to your video editing business needs. From setting up your online store to integrating payment gateways and optimizing for conversions related to video editing services, we've got you covered.</p>
                    </div>
                </div>
                <div className="VideoEditing-platform-info-item">
                    <div className="VideoEditing-platform-info-logo">
                        <img src={wordpress} alt="WordPress Logo" />
                    </div>
                    <div>
                        <h3 className="VideoEditing-platform-info-heading">WordPress</h3>
                        <p className="VideoEditing-platform-info-description">Explore the endless possibilities of WordPress with our experienced team. Whether you need a simple blog or a complex corporate website related to video editing services, we can design and develop a WordPress solution that meets your requirements and exceeds your expectations.</p>
                    </div>
                </div>
                <div className="VideoEditing-platform-info-item">
                    <div className="VideoEditing-platform-info-logo">
                        <img src={wix} alt="Wix Logo" />
                    </div>
                    <div>
                        <h3 className="VideoEditing-platform-info-heading">Wix</h3>
                        <p className="VideoEditing-platform-info-description">Unlock the full potential of Wix with our customization and development services. We can transform your Wix website into a professional and engaging online presence, utilizing Wix's intuitive platform to showcase your brand and captivate your audience interested in video editing services.</p>
                    </div>
                </div>
                <div className="VideoEditing-platform-info-item">
                    <div className="VideoEditing-platform-info-logo">
                        <img src={html} alt="HTML Logo" />
                    </div>
                    <div>
                        <h3 className="VideoEditing-platform-info-heading">HTML</h3>
                        <p className="VideoEditing-platform-info-description">Harness the power of HTML to build sleek and responsive websites from scratch. Our HTML experts ensure clean, semantic code and pixel-perfect designs, resulting in websites that load fast and look great on any device, ideal for showcasing your video editing portfolio.</p>
                    </div>
                </div>
                <div className="VideoEditing-platform-info-item">
                    <div className="VideoEditing-platform-info-logo">
                        <img src={react} alt="React Logo" />
                    </div>
                    <div>
                        <h3 className="VideoEditing-platform-info-heading">React</h3>
                        <p className="VideoEditing-platform-info-description">Experience the latest in front-end development with React. Our React developers create dynamic and interactive user interfaces that enhance user experience and streamline navigation for your video editing projects. Let us elevate your web presence with the power of React.</p>
                    </div>
                </div>
                <div className="VideoEditing-platform-info-item">
                    <div className="VideoEditing-platform-info-logo">
                        <img src={djnago} alt="Django Logo" />
                    </div>
                    <div>
                        <h3 className="VideoEditing-platform-info-heading">Django</h3>
                        <p className="VideoEditing-platform-info-description">Empower your web applications with Django, the high-level Python web framework. Our Django developers build robust and scalable web solutions, leveraging Django's built-in features for authentication, security, and database management for your video editing applications.</p>
                    </div>
                </div>
            </section> */}



            <section className="VideoEditing-service-overview">
                <div className="VideoEditing-service-overview-banner">
                    <div className="VideoEditing-service-overview-banner-content">
                        <h1 className="VideoEditing-service-overview-banner-heading">Premium Video Editing Services</h1>
                        <p>Welcome to our premium video editing services page. We specialize in crafting high-quality, visually stunning videos tailored to your unique needs. Our team utilizes professional editing tools and techniques to bring your vision to life with precision and excellence.</p>
                        <button>Contact Us</button>
                    </div>
                    <div className="VideoEditing-service-overview-banner-image">
                        <img src={web1} alt="Video Editing Banner" />
                    </div>
                </div>
            </section>



            <section className="VideoEditing-expertise">
                <h2 className="VideoEditing-expertise-title">Expertise and Experience in Video Editing</h2>
                <div className="VideoEditing-expertise-content">
                    <div className="VideoEditing-expertise-item">
                        <h3 className="VideoEditing-expertise-heading">Custom Video Editing</h3>
                        <p className="VideoEditing-expertise-description">We specialize in crafting custom video editing solutions tailored to your unique needs. Our experienced team ensures that your videos stand out with original edits and creative enhancements.</p>
                    </div>
                    <div className="VideoEditing-expertise-item">
                        <h3 className="VideoEditing-expertise-heading">Professional Editing Tools</h3>
                        <p className="VideoEditing-expertise-description">With expertise in industry-standard editing software like Adobe Premiere Pro, Final Cut Pro, and DaVinci Resolve, we create high-quality edits that meet professional standards and exceed your expectations.</p>
                    </div>
                    <div className="VideoEditing-expertise-item">
                        <h3 className="VideoEditing-expertise-heading">Creative Effects and Transitions</h3>
                        <p className="VideoEditing-expertise-description">We specialize in adding creative effects and transitions to enhance the visual appeal of your videos. From smooth transitions to eye-catching effects, we ensure that your videos captivate your audience from start to finish.</p>
                    </div>
                    <div className="VideoEditing-expertise-item">
                        <h3 className="VideoEditing-expertise-heading">Storyboarding and Narrative</h3>
                        <p className="VideoEditing-expertise-description">Our team excels in storyboard creation and narrative development, ensuring that your videos tell a compelling story that resonates with your audience. We work closely with you to bring your vision to life through effective storytelling.</p>
                    </div>
                    <div className="VideoEditing-expertise-item">
                        <h3 className="VideoEditing-expertise-heading">Color Grading and Audio Enhancement</h3>
                        <p className="VideoEditing-expertise-description">We specialize in color grading to enhance the visual aesthetics of your videos and audio enhancement to ensure clear and immersive sound. With our expertise, your videos will look and sound polished and professional.</p>
                    </div>
                </div>
            </section>




            <section className="VideoEditing-client-testimonials">
                <h2 className="VideoEditing-client-testimonials-title">Client Testimonials</h2>
                <div className="VideoEditing-testimonial-container">
                    <div className="VideoEditing-testimonial">
                        <p className="VideoEditing-testimonial-text">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."</p>
                        <p className="VideoEditing-testimonial-author">- John Doe</p>
                    </div>
                    <div className="VideoEditing-testimonial">
                        <p className="VideoEditing-testimonial-text">"Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."</p>
                        <p className="VideoEditing-testimonial-author">- Jane Smith</p>
                    </div>
                    <div className="VideoEditing-testimonial">
                        <p className="VideoEditing-testimonial-text">"Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."</p>
                        <p className="VideoEditing-testimonial-author">- Michael Johnson</p>
                    </div>
                    {/* Add more testimonials as needed */}
                </div>
            </section>



            <section className="VideoEditing-call-to-action">
                <div className="VideoEditing-cta-container">
                    <div className="VideoEditing-cta-content">
                        <h2>Ready to Get Started?</h2>
                        <p>Contact us today for a consultation or request a quote to begin your video editing journey.</p>
                        <button>Contact Us</button>
                    </div>
                    <div className="VideoEditing-cta-image">
                        <img src={web4} alt="Inspiring" />
                    </div>
                </div>
            </section>




        </div>
    )
}
