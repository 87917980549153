import React from 'react'
import '../css/Footer.css'
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="site-footer">
      <div className="footer-container">
        <div className="footer-section">
          <h4>GET IN TOUCH</h4>
          <p>Address: Lucknow, India</p>
          <p>Email: info@advancetechno.org</p>
          <p>Phone: +91 9918141119</p>
          <div className="social-links">
            {/* Social media icons */}
          </div>
        </div>

        <div className="footer-section">
          <h4>Company</h4>
          <Link to="/about">About Us</Link>
          <Link to="/contact_us_@_mindmap">Contact Us</Link>
          <Link to="/social_connect">Terms & Policies</Link>
          <Link to="/social_connect">Privacy Policy</Link>
          <Link to="/social_connect">Abou Us</Link>
          <Link to="/social_connect">Social Media</Link>
          {/* Other company links */}
        </div>

        <div className="footer-section">
          <h4>Shopping With Us</h4>
          <Link to="/">Home</Link>
          {/* Other shopping links */}
        </div>

         <div className="footer-section">
          <h4>NEWSLETTER SIGNUP</h4>
          <p>Subscribe to our newsletter for being updated.</p>
          <form>
            <input type="email" placeholder="Your email address" />
            <button type="submit">Subscribe</button>
            <label>
              <input type="checkbox" /> I agree with the terms and conditions.
            </label>
          </form>
        </div> 
      </div>

      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Do-Out Creators. All rights reserved.</p>
        <button onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          ↑ Back to Top
        </button>
      </div>
    </footer>
  );
}

export default Footer;