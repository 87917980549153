import React from 'react';
import web1 from '../Media/web1.png';
import web4 from '../Media/web4.png';
import web2 from '../Media/shopify.webp';
import wordpress from '../Media/wordpress.png';
import wix from '../Media/wix.png';
import html from '../Media/html.png';
import react from '../Media/react.jpg';
import djnago from '../Media/djnago.png';
import web3 from '../Media/web3.png';
import techgrip from '../Media/techgrip.jpeg';
import ddwals from '../Media/ddwals.jpeg';
import styles from '../Media/styles.jpeg';


import '../css/Websitedevelopment.css';


const WebsiteDevelopment = () => {

    const projects = [
        { imageSrc: techgrip, websiteUrl: 'https://techgrip.com.au/' },
        { imageSrc: ddwals, websiteUrl: 'https://ddwalls.in/' },
        { imageSrc: styles, websiteUrl: 'https://styleshift.in/' },
        // Add more projects as needed
    ];

    // Function to redirect to the specified website URL when an image is clicked
    const handleImageClick = (websiteUrl) => {
        window.open(websiteUrl, '_blank'); // Open the website in a new tab
    };

    return (
        <div className="website-development-container">
            
            
            <div className="banner">
                <div className="banner-content">
                    <h1 className="banner-heading">Website Development Services</h1>
                    <p>Welcome to our website development services page. We specialize in creating stunning websites tailored to your needs, utilizing platforms like Shopify, WordPress, and Wix to bring your vision to life.</p>
                    <button>Contact Us</button>
                </div>
                <div className="banner-image">
                    <img src={web3} alt="Website Development Banner" />
                </div>
            </div>


            <section className="service-overview">
                <div className="service-overview-banner">
                    <div className="service-overview-banner-content">
                        <h1 className="service-overview-banner-heading">Premium Website Development Services</h1>
                        <p>Welcome to our premium website development services page. We specialize in crafting high-quality, visually stunning websites tailored to your unique needs. Our team utilizes platforms like Shopify, WordPress, and Wix to bring your vision to life with precision and excellence.</p>
                        <button>Contact Us</button>
                    </div>
                    <div className="service-overview-banner-image">
                        <img src={web1} alt="Website Development Banner" />
                    </div>
                </div>
            </section>



            <section className="platform-info">
                <h2 className="platform-info-title">Platform-specific Information</h2>
                <div className="platform-info-item">
                    <div className="platform-info-logo">
                        <img src={web2} alt="Shopify Logo" />
                    </div>
                    <div>
                        <h3 className="platform-info-heading">Shopify</h3>
                        <p className="platform-info-description">Discover how our Shopify experts can create seamless e-commerce solutions tailored to your business needs. From setting up your online store to integrating payment gateways and optimizing for conversions, we've got you covered.</p>
                    </div>
                </div>
                <div className="platform-info-item">
                    <div className="platform-info-logo">
                        <img src={wordpress} alt="WordPress Logo" />
                    </div>
                    <div>
                        <h3 className="platform-info-heading">WordPress</h3>
                        <p className="platform-info-description">Explore the endless possibilities of WordPress with our experienced team. Whether you need a simple blog or a complex corporate website, we can design and develop a WordPress solution that meets your requirements and exceeds your expectations.</p>
                    </div>
                </div>
                <div className="platform-info-item">
                    <div className="platform-info-logo">
                        <img src={wix} alt="Wix Logo" />
                    </div>
                    <div>
                        <h3 className="platform-info-heading">Wix</h3>
                        <p className="platform-info-description">Unlock the full potential of Wix with our customization and development services. We can transform your Wix website into a professional and engaging online presence, utilizing Wix's intuitive platform to showcase your brand and captivate your audience.</p>
                    </div>
                </div>
                <div className="platform-info-item">
                    <div className="platform-info-logo">
                        <img src={html} alt="HTML Logo" />
                    </div>
                    <div>
                        <h3 className="platform-info-heading">HTML</h3>
                        <p className="platform-info-description">Harness the power of HTML to build sleek and responsive websites from scratch. Our HTML experts ensure clean, semantic code and pixel-perfect designs, resulting in websites that load fast and look great on any device.</p>
                    </div>
                </div>
                <div className="platform-info-item">
                    <div className="platform-info-logo">
                        <img src={react} alt="React Logo" />
                    </div>
                    <div>
                        <h3 className="platform-info-heading">React</h3>
                        <p className="platform-info-description">Experience the latest in front-end development with React. Our React developers create dynamic and interactive user interfaces that enhance user experience and streamline navigation. Let us elevate your web presence with the power of React.</p>
                    </div>
                </div>
                <div className="platform-info-item">
                    <div className="platform-info-logo">
                        <img src={djnago} alt="Django Logo" />
                    </div>
                    <div>
                        <h3 className="platform-info-heading">Django</h3>
                        <p className="platform-info-description">Empower your web applications with Django, the high-level Python web framework. Our Django developers build robust and scalable web solutions, leveraging Django's built-in features for authentication, security, and database management.</p>
                    </div>
                </div>
            </section>


            <section className="portfolio-showcase">
                <h2>Portfolio Showcase</h2>
                <div className="portfolio-grid">
                    {/* Map through the projects array and render each project */}
                    {projects.map((project, index) => (
                        <div className="portfolio-item" key={index} onClick={() => handleImageClick(project.websiteUrl)}>
                            <img src={project.imageSrc} alt={`Project ${index + 1}`} />
                        </div>
                    ))}
                </div>
            </section>


            <section className="expertise">
                <h2 className="expertise-title">Expertise and Experience</h2>
                <div className="expertise-content">
                    <div className="expertise-item">
                        <h3 className="expertise-heading">Custom Website Development</h3>
                        <p className="expertise-description">We specialize in crafting custom websites tailored to your unique business needs. Our experienced team of developers ensures that your website stands out with its original design and features.</p>
                    </div>
                    <div className="expertise-item">
                        <h3 className="expertise-heading">E-commerce Solutions</h3>
                        <p className="expertise-description">With expertise in platforms like Shopify, WooCommerce, and Magento, we create seamless e-commerce solutions that drive sales and enhance customer experience. From product listings to secure payment gateways, we've got you covered.</p>
                    </div>
                    <div className="expertise-item">
                        <h3 className="expertise-heading">Responsive Design</h3>
                        <p className="expertise-description">We prioritize responsive design to ensure your website looks and functions flawlessly across all devices and screen sizes. From desktops to smartphones, your audience will enjoy a consistent and user-friendly experience.</p>
                    </div>
                    <div className="expertise-item">
                        <h3 className="expertise-heading">Content Management Systems</h3>
                        <p className="expertise-description">Our team is proficient in various content management systems (CMS) such as WordPress, Drupal, and Joomla. We leverage these platforms to empower you with easy content management and website updates.</p>
                    </div>
                    <div className="expertise-item">
                        <h3 className="expertise-heading">SEO Optimization</h3>
                        <p className="expertise-description">We implement SEO best practices to improve your website's visibility and ranking on search engine results pages (SERPs). From keyword research to on-page optimization, we help you attract organic traffic and grow your online presence.</p>
                    </div>
                </div>
            </section>


            <section className="client-testimonials">
                <h2 className="client-testimonials-title">Client Testimonials</h2>
                <div className="testimonial-container">
                    <div className="testimonial">
                        <p className="testimonial-text">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."</p>
                        <p className="testimonial-author">- John Doe</p>
                    </div>
                    <div className="testimonial">
                        <p className="testimonial-text">"Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."</p>
                        <p className="testimonial-author">- Jane Smith</p>
                    </div>
                    <div className="testimonial">
                        <p className="testimonial-text">"Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."</p>
                        <p className="testimonial-author">- Michael Johnson</p>
                    </div>
                    {/* Add more testimonials as needed */}
                </div>
            </section>


            <section className="call-to-action">
                <div className="cta-container">
                    <div className="cta-content">
                        <h2>Ready to Get Started?</h2>
                        <p>Contact us today for a consultation or request a quote to begin your website development journey.</p>
                        <button>Contact Us</button>
                    </div>
                    <div className="cta-image">
                        <img src={web4} alt="Inspiring" />
                    </div>
                </div>
            </section>

        </div>
    );
}

export default WebsiteDevelopment;