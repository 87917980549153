import '../css/AboutUs.css'
import logo from '../Media/dout.jpeg';
import React from 'react';

const About = () => {
  return (
    <>
      <div className="about-container">
        <div className="about-header">
          {/* <img src={logo} alt="Founder" className="about-founder-image" /> */}

          <h1 className="about-heading">DO-OUT CREATORES</h1>
          <p className="about-tagline">Empowering Tomorrow's Digital Success Today!</p>
          <p className="about-founder-description">Welcome to Do-out Creators, where creativity meets capability to redefine the future of digital marketing.</p>
          <p className="about-founder-description">At Do-out Creators, we are a powerhouse of seasoned professionals, each bringing a unique blend of experience and expertise to the forefront. With a collective reservoir of knowledge spanning diverse domains within marketing, technology, design, and strategy, we are equipped to tackle every challenge with precision and creativity.</p>
          <p className="about-founder-description">What truly sets us apart is our unwavering commitment to mentorship and visionary entrepreneurship. We believe in cultivating talent and empowering the next generation of digital trailblazers. Our team of mentors, comprised of visionary entrepreneurs, stands as a beacon of guidance, steering aspiring marketers towards success in the ever-evolving digital realm.</p>
          <p className="about-founder-description">Fueled by our passion for innovation and driven by collaborative spirit, we are dedicated to pushing the boundaries of digital marketing. From pioneering campaigns to cutting-edge technologies, we thrive on transforming ideas into impactful realities, consistently exceeding expectations.</p>
          <p className="about-founder-description">Join us at Do-out Creators and embark on a journey to unleash the full potential of digital marketing. Together, let's create, innovate, and shape the future of the industry.</p>
          <p className="about-founder-description"></p>
          <p className="about-founder-description"></p>
          <p className="about-founder-description"></p>
          <p className="about-founder-description"></p>
          <p className="about-founder-description"></p>
        </div>
        <div className="about-content">
          <div className="about-founder">
            {/* <img src={logo} alt="Founder" className="about-founder-image" /> */}
            {/* <div className="about-founder-details">
              <h2 className="about-founder-name">Harsh Kaithwas & Rakshit Tripathi</h2>
              <p className="about-founder-title">Founder & CEO</p>
              <p className="about-founder-description">Harsh Kaitwhas is a visionary entrepreneur with a passion for education and mental health. He founded MindMap Consultancy with the goal of creating a society where everyone has access to the education and resources they need to achieve their full potential.</p>
            </div> */}
          </div>
          <div className="about-cards">
            <div className="about-card">
              <h3 className="about-card-title">Our Mission</h3>
              <p className="about-card-description">At Do-out Creators, our mission is to revolutionize the digital marketing landscape by fostering creativity, innovation, and excellence.</p>
              <p className="about-card-description">We are committed to empowering businesses of all sizes to thrive in the digital age through strategic guidance, cutting-edge technologies, and unparalleled expertise.</p>
              <p className="about-card-description">Driven by a relentless pursuit of perfection, we strive to exceed expectations, deliver measurable results, and create lasting value for our clients.</p>
              <p className="about-card-description">Through mentorship, collaboration, and a dedication to pushing boundaries, we aim to inspire and empower the next generation of digital marketers to reach new heights of success.</p>
              <p className="about-card-description">Together, we envision a future where creativity knows no bounds, innovation drives growth, and every digital endeavor is met with unparalleled success. Join us in shaping that future, one campaign at a time.</p>
            </div>
            <div className="about-card">
              <h3 className="about-card-title">Our Vision</h3>
              <p className="about-card-description">At Do-out Creators, our vision is to be the catalyst for transformation in the digital marketing industry.</p>
              <p className="about-card-description">We envision a future where creativity is boundless, innovation is the norm, and success is achievable for all who dare to dream big.</p>
              <p className="about-card-description">Driven by our passion for pushing boundaries and redefining what's possible, we aspire to set new standards of excellence in every campaign we undertake.</p>
              <p className="about-card-description">We see ourselves as pioneers, leading the charge towards a digital landscape where businesses thrive, brands resonate, and connections are forged on a deeper level.</p>
              <p className="about-card-description">Through our visionary leadership, relentless pursuit of innovation, and unwavering commitment to our clients' success, we aim to inspire and empower others to embark on their own journeys of digital transformation.</p>
              <p className="about-card-description">Together, let's shape a future where possibilities are limitless, and every idea has the power to change the world.</p>
            </div>
            <div className="about-card">
              <h3 className="about-card-title">Our Services</h3>
              <ul className="about-card-list">
                <li>1. Website Development</li>
                <li>2. Performance Marketing</li>
                <li>3. Business Consultation</li>
                <li>4. Video Editing</li>
                <li>5. Graphics Designing</li>
                <li>6. Lead Generation</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
