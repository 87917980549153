import React from 'react';
import web1 from '../Media/web1.png';
import web4 from '../Media/web4.png';
import web2 from '../Media/fb.png';
import wordpress from '../Media/ig.png';
import wix from '../Media/google.png';
import html from '../Media/sc.png';
import react from '../Media/tt.png';
import djnago from '../Media/ln.png';
import web3 from '../Media/pms.png';

import '../css/PerformanceMarketing.css';


export default function PerformanceMarketing() {
    return (
        <div className="performance-marketing-container">



            <div className="performance-marketing-banner">
                <div className="performance-marketing-banner-content">
                    <h1 className="performance-marketing-banner-heading">Performance Marketing Services</h1>
                    <p>Welcome to our performance marketing services page. We specialize in crafting high-impact marketing campaigns tailored to your business goals. Let us help you achieve measurable results and drive growth for your business.</p>
                    <button>Contact Us</button>
                </div>
                <div className="performance-marketing-banner-image">
                    <img src={web3} alt="Performance Marketing Banner" />
                </div>
            </div>





            <section className="performance-marketing-platform-info">
                <h2 className="performance-marketing-platform-info-title">Platform-specific Information</h2>
                <div className="performance-marketing-platform-info-item">
                    <div className="performance-marketing-platform-info-logo">
                        <img src={web2} alt="Facebook Logo" />
                    </div>
                    <div>
                        <h3 className="performance-marketing-platform-info-heading">Facebook</h3>
                        <p className="performance-marketing-platform-info-description">Discover how our Facebook marketing experts can create engaging social media campaigns tailored to your business needs. From increasing brand awareness to driving conversions, we've got you covered on the world's largest social network.</p>
                    </div>
                </div>
                <div className="performance-marketing-platform-info-item">
                    <div className="performance-marketing-platform-info-logo">
                        <img src={wordpress} alt="Instagram Logo" />
                    </div>
                    <div>
                        <h3 className="performance-marketing-platform-info-heading">Instagram</h3>
                        <p className="performance-marketing-platform-info-description">Explore the visual storytelling potential of Instagram with our experienced team. Whether you need to grow your audience or promote your products, we can design and execute Instagram marketing strategies that resonate with your target audience.</p>
                    </div>
                </div>
                <div className="performance-marketing-platform-info-item">
                    <div className="performance-marketing-platform-info-logo">
                        <img src={wix} alt="Google Logo" />
                    </div>
                    <div>
                        <h3 className="performance-marketing-platform-info-heading">Google</h3>
                        <p className="performance-marketing-platform-info-description">Unlock the power of Google Ads with our customization and optimization services. We can help you reach your target audience through Google's vast advertising network, driving traffic and conversions for your business.</p>
                    </div>
                </div>
                <div className="performance-marketing-platform-info-item">
                    <div className="performance-marketing-platform-info-logo">
                        <img src={html} alt="Snapchat Logo" />
                    </div>
                    <div>
                        <h3 className="performance-marketing-platform-info-heading">Snapchat</h3>
                        <p className="performance-marketing-platform-info-description">Tap into the creativity of Snapchat to engage with a younger demographic. Our Snapchat marketing experts can help you create immersive ad experiences that resonate with Gen Z and drive results for your brand.</p>
                    </div>
                </div>
                <div className="performance-marketing-platform-info-item">
                    <div className="performance-marketing-platform-info-logo">
                        <img src={react} alt="TikTok Logo" />
                    </div>
                    <div>
                        <h3 className="performance-marketing-platform-info-heading">TikTok</h3>
                        <p className="performance-marketing-platform-info-description">Experience the viral potential of TikTok with our creative marketing strategies. Our TikTok experts can help your brand gain visibility and engagement in this rapidly growing platform, reaching millions of users worldwide.</p>
                    </div>
                </div>
                <div className="performance-marketing-platform-info-item">
                    <div className="performance-marketing-platform-info-logo">
                        <img src={djnago} alt="LinkedIn Logo" />
                    </div>
                    <div>
                        <h3 className="performance-marketing-platform-info-heading">LinkedIn</h3>
                        <p className="performance-marketing-platform-info-description">Empower your B2B marketing efforts with LinkedIn's professional networking platform. Our LinkedIn marketing specialists can help you generate leads, build relationships, and establish thought leadership in your industry.</p>
                    </div>
                </div>
            </section>





            <section className="performance-marketing">
                <div className="performance-marketing-overview">
                    <div className="performance-marketing-overview-content">
                        <h1 className="performance-marketing-overview-heading">Premium Digital Marketing Services</h1>
                        <p>Welcome to our premium marketing services page. We specialize in crafting high-quality, visually stunning marketing campaigns tailored to your unique needs. Our team utilizes platforms like Facebook, Instagram, Meta, Google, Snapchat, TikTok, and more to bring your vision to life with precision and excellence.</p>
                        <button>Contact Us</button>
                    </div>
                    <div className="performance-marketing-overview-image">
                        <img src={web1} alt="Marketing Banner" />
                    </div>
                </div>
            </section>




            <section className="performance-marketing-expertise">
                <h2 className="performance-marketing-expertise-title">Expertise and Experience</h2>
                <div className="performance-marketing-expertise-content">
                    <div className="performance-marketing-expertise-item">
                        <h3 className="performance-marketing-expertise-heading">Custom Marketing Campaigns</h3>
                        <p className="performance-marketing-expertise-description">We specialize in crafting custom marketing campaigns tailored to your unique business needs. Our experienced team ensures that your campaigns stand out with original strategies and creative content.</p>
                    </div>
                    <div className="performance-marketing-expertise-item">
                        <h3 className="performance-marketing-expertise-heading">Social Media Solutions</h3>
                        <p className="performance-marketing-expertise-description">With expertise in platforms like Facebook, Instagram, and LinkedIn, we create engaging social media solutions that drive engagement and enhance brand visibility. From content creation to audience targeting, we've got you covered.</p>
                    </div>
                    <div className="performance-marketing-expertise-item">
                        <h3 className="performance-marketing-expertise-heading">Responsive Ad Design</h3>
                        <p className="performance-marketing-expertise-description">We prioritize responsive ad design to ensure your campaigns look and perform flawlessly across all devices and screen sizes. From desktops to mobile devices, your audience will enjoy a seamless and engaging experience.</p>
                    </div>
                    <div className="performance-marketing-expertise-item">
                        <h3 className="performance-marketing-expertise-heading">Content Creation</h3>
                        <p className="performance-marketing-expertise-description">Our team excels in creating compelling content for various platforms, including blogs, videos, and infographics. We leverage creative storytelling and strategic messaging to captivate your audience and drive action.</p>
                    </div>
                    {/* <div className="performance-marketing-expertise-item">
                        <h3 className="performance-marketing-expertise-heading">SEO Strategy</h3>
                        <p className="performance-marketing-expertise-description">We implement SEO strategies to improve your online visibility and ranking on search engine results pages (SERPs). From keyword optimization to backlink building, we help you attract organic traffic and maximize your online presence.</p>
                    </div> */}
                </div>
            </section>




            <section className="performance-marketing-call-to-action-one">
                <div className="performance-marketing-cta-container-one">
                    <div className="performance-marketing-cta-content-one">
                        <h2>Ready to Get Started?</h2>
                        <p>Contact us today for a consultation or request a quote to begin your marketing journey.</p>
                        <button>Contact Us</button>
                    </div>
                    <div className="performance-marketing-cta-image-one">
                        <img src={web4} alt="Inspiring" />
                    </div>
                </div>
            </section>


            <section className="performance-marketing-client-testimonials">
                <h2 className="performance-marketing-client-testimonials-title">Client Testimonials</h2>
                <div className="performance-marketing-testimonial-container">
                    <div className="performance-marketing-testimonial">
                        <p className="performance-marketing-testimonial-text">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."</p>
                        <p className="performance-marketing-testimonial-author">- John Doe</p>
                    </div>
                    <div className="performance-marketing-testimonial">
                        <p className="performance-marketing-testimonial-text">"Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."</p>
                        <p className="performance-marketing-testimonial-author">- Jane Smith</p>
                    </div>
                    <div className="performance-marketing-testimonial">
                        <p className="performance-marketing-testimonial-text">"Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."</p>
                        <p className="performance-marketing-testimonial-author">- Michael Johnson</p>
                    </div>
                    {/* Add more testimonials as needed */}
                </div>
            </section>




            <section className="performance-marketing-call-to-action">
                <div className="performance-marketing-cta-container">
                    <div className="performance-marketing-cta-content">
                        <h2>Ready to Get Started?</h2>
                        <p>Contact us today for a consultation or request a quote to begin your marketing journey.</p>
                        <button>Contact Us</button>
                    </div>
                    <div className="performance-marketing-cta-image">
                        <img src={web4} alt="Inspiring" />
                    </div>
                </div>
            </section>




        </div>

    )
}
