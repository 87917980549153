import React from 'react';
import web1 from '../Media/web1.png';
import web4 from '../Media/web4.png';
import web2 from '../Media/shopify.webp';
import wordpress from '../Media/wordpress.png';
import wix from '../Media/wix.png';
import html from '../Media/html.png';
import react from '../Media/react.jpg';
import djnago from '../Media/djnago.png';
import web3 from '../Media/web3.png';
import leadss from '../Media/leadss.png';
import '../css/LeadGeneration.css';


export default function LeadGeneration() {
  return (
    <div className="lead-generation-container">


      <div className="lead-generation-banner">
        <div className="lead-generation-banner-content">
          <h1 className="lead-generation-banner-heading">Lead Generation Services</h1>
          <p>Welcome to our lead generation services page. We specialize in generating high-quality leads tailored to your business needs, utilizing effective strategies and techniques to drive conversions.</p>
          <button>Contact Us</button>
        </div>
        <div className="lead-generation-banner-image">
          <img src={leadss} alt="Lead Generation Banner" />
        </div>
      </div>





      {/* <section className="lead-generation-platform-info">
        <h2 className="lead-generation-platform-info-title">Platform-specific Information</h2>
        <div className="lead-generation-platform-info-item">
          <div className="lead-generation-platform-info-logo">
            <img src={web2} alt="Shopify Logo" />
          </div>
          <div>
            <h3 className="lead-generation-platform-info-heading">Shopify</h3>
            <p className="lead-generation-platform-info-description">Discover how our Shopify experts can create seamless e-commerce solutions tailored to your business needs. From setting up your online store to integrating payment gateways and optimizing for conversions, we've got you covered.</p>
          </div>
        </div>
        <div className="lead-generation-platform-info-item">
          <div className="lead-generation-platform-info-logo">
            <img src={wordpress} alt="WordPress Logo" />
          </div>
          <div>
            <h3 className="lead-generation-platform-info-heading">WordPress</h3>
            <p className="lead-generation-platform-info-description">Explore the endless possibilities of WordPress with our experienced team. Whether you need a simple blog or a complex corporate website, we can design and develop a WordPress solution that meets your requirements and exceeds your expectations.</p>
          </div>
        </div>
        <div className="lead-generation-platform-info-item">
          <div className="lead-generation-platform-info-logo">
            <img src={wix} alt="Wix Logo" />
          </div>
          <div>
            <h3 className="lead-generation-platform-info-heading">Wix</h3>
            <p className="lead-generation-platform-info-description">Unlock the full potential of Wix with our customization and development services. We can transform your Wix website into a professional and engaging online presence, utilizing Wix's intuitive platform to showcase your brand and captivate your audience.</p>
          </div>
        </div>
        <div className="lead-generation-platform-info-item">
          <div className="lead-generation-platform-info-logo">
            <img src={html} alt="HTML Logo" />
          </div>
          <div>
            <h3 className="lead-generation-platform-info-heading">HTML</h3>
            <p className="lead-generation-platform-info-description">Harness the power of HTML to build sleek and responsive websites from scratch. Our HTML experts ensure clean, semantic code and pixel-perfect designs, resulting in websites that load fast and look great on any device.</p>
          </div>
        </div>
        <div className="lead-generation-platform-info-item">
          <div className="lead-generation-platform-info-logo">
            <img src={react} alt="React Logo" />
          </div>
          <div>
            <h3 className="lead-generation-platform-info-heading">React</h3>
            <p className="lead-generation-platform-info-description">Experience the latest in front-end development with React. Our React developers create dynamic and interactive user interfaces that enhance user experience and streamline navigation. Let us elevate your web presence with the power of React.</p>
          </div>
        </div>
        <div className="lead-generation-platform-info-item">
          <div className="lead-generation-platform-info-logo">
            <img src={djnago} alt="Django Logo" />
          </div>
          <div>
            <h3 className="lead-generation-platform-info-heading">Django</h3>
            <p className="lead-generation-platform-info-description">Empower your web applications with Django, the high-level Python web framework. Our Django developers build robust and scalable web solutions, leveraging Django's built-in features for authentication, security, and database management.</p>
          </div>
        </div>
      </section> */}




      <section className="lead-generation-service-overview">
        <div className="lead-generation-service-overview-banner">
          <div className="lead-generation-service-overview-banner-content">
            <h1 className="lead-generation-service-overview-banner-heading">Premium Lead Generation Services</h1>
            <p>Welcome to our premium lead generation services page. We specialize in crafting high-quality, effective strategies tailored to your unique needs. Our team utilizes innovative techniques to drive conversions and maximize your ROI with precision and excellence.</p>
            <button>Contact Us</button>
          </div>
          <div className="lead-generation-service-overview-banner-image">
            <img src={web1} alt="Lead Generation Banner" />
          </div>
        </div>
      </section>



      <section className="lead-generation-expertise">
        <h2 className="lead-generation-expertise-title">Lead Generation Expertise and Experience</h2>
        <div className="lead-generation-expertise-content">
          <div className="lead-generation-expertise-item">
            <h3 className="lead-generation-expertise-heading">Custom Lead Generation Development</h3>
            <p className="lead-generation-expertise-description">We specialize in crafting custom lead generation strategies tailored to your unique business needs. Our experienced team ensures that your lead generation efforts stand out with innovative approaches and effective techniques.</p>
          </div>
          <div className="lead-generation-expertise-item">
            <h3 className="lead-generation-expertise-heading">E-commerce Lead Generation Solutions</h3>
            <p className="lead-generation-expertise-description">With expertise in platforms like Shopify, WooCommerce, and Magento, we create seamless e-commerce lead generation solutions that drive conversions and enhance customer engagement. From lead capture to nurturing strategies, we've got you covered.</p>
          </div>
          <div className="lead-generation-expertise-item">
            <h3 className="lead-generation-expertise-heading">Responsive Lead Generation Design</h3>
            <p className="lead-generation-expertise-description">We prioritize responsive design to ensure your lead generation campaigns look and perform flawlessly across all devices and screen sizes. From desktops to smartphones, your audience will enjoy a consistent and engaging experience.</p>
          </div>
          <div className="lead-generation-expertise-item">
            <h3 className="lead-generation-expertise-heading">Lead Generation Content Management Systems</h3>
            <p className="lead-generation-expertise-description">Our team is proficient in various content management systems (CMS) such as WordPress, Drupal, and Joomla, specifically tailored for lead generation purposes. We leverage these platforms to empower you with easy lead management and campaign optimization.</p>
          </div>
          <div className="lead-generation-expertise-item">
            <h3 className="lead-generation-expertise-heading">SEO Lead Generation Optimization</h3>
            <p className="lead-generation-expertise-description">We implement SEO best practices to improve your lead generation campaigns' visibility and effectiveness. From keyword research to on-page optimization, we help you attract quality leads and grow your customer base.</p>
          </div>
        </div>
      </section>



      <section className="lead-generation-client-testimonials">
        <h2 className="lead-generation-client-testimonials-title">Client Testimonials</h2>
        <div className="lead-generation-testimonial-container">
          <div className="lead-generation-testimonial">
            <p className="lead-generation-testimonial-text">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."</p>
            <p className="lead-generation-testimonial-author">- John Doe</p>
          </div>
          <div className="lead-generation-testimonial">
            <p className="lead-generation-testimonial-text">"Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."</p>
            <p className="lead-generation-testimonial-author">- Jane Smith</p>
          </div>
          <div className="lead-generation-testimonial">
            <p className="lead-generation-testimonial-text">"Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."</p>
            <p className="lead-generation-testimonial-author">- Michael Johnson</p>
          </div>
          {/* Add more testimonials as needed */}
        </div>
      </section>



      <section className="lead-generation-call-to-action">
        <div className="lead-generation-cta-container">
          <div className="lead-generation-cta-content">
            <h2>Ready to Generate More Leads?</h2>
            <p>Get in touch with us today to discover how we can help you boost your lead generation efforts and grow your business.</p>
            <button className="lead-generation-cta-button">Start Generating Leads</button>
          </div>
          <div className="lead-generation-cta-image">
            <img src={web4} alt="Inspiring" />
          </div>
        </div>
      </section>








    </div>
  )
}
