import React from 'react';
import '../css/Team.css';
import johnDoeImage from '../Media/pms.png';
import janeSmithImage from '../Media/pms.png';
import michaelJohnsonImage from '../Media/pms.png';

const Team = () => {
    // Define an array of objects representing your team members
    const teamMembers = [
        {
            id: 1,
            name: 'Harsh Kaithwas',
            position: 'CEO',
            bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            image: johnDoeImage
        },
        {
            id: 2,
            name: 'Rakshit Tripathi',
            position: 'CTO',
            bio: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            image: janeSmithImage
        },
        {
            id: 3,
            name: 'Yudhistir Kumar Naval',
            position: 'Head of Marketing',
            bio: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
            image: michaelJohnsonImage
        },
        {
            id: 3,
            name: 'Sumit Goyal',
            position: 'Head of Marketing',
            bio: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
            image: michaelJohnsonImage
        },
        {
            id: 3,
            name: 'M. Amaan',
            position: 'Head of Marketing',
            bio: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
            image: michaelJohnsonImage
        }

        // Add more team members as needed
    ];

    return (
        <section className="team-section">
            <h2 className="team-title">Our Team</h2>
            <div className="team-container">
                {teamMembers.map(member => (
                    <div className="team-member" key={member.id}>
                        <img src={member.image} alt={member.name} className="member-image" />
                        <h3 className="member-name">{member.name}</h3>
                        <p className="member-position">{member.position}</p>
                        <p className="member-bio">{member.bio}</p>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Team;
