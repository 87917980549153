import React from "react";
// import Card_00 from '../media/loan.jpg'
import { Link } from 'react-router-dom';
import "../css/Homepage2.css";



function MId_0() {
  return (
    <section className="career-guidance">
      <div className="career-guidance-container">
        <div className="career-guidance-content">
          <h1 className="career-guidance-title">How can career guidance shape your professional journey?</h1>
          <p className="career-guidance-text">
          Career guidance is crucial for shaping your professional journey. Expert career counselors provide valuable insights and personalized advice based on your strengths, interests, and goals. They help you make informed decisions and navigate career challenges for success and fulfillment.
          </p>
          <Link to='connect'>
            <button className="career-guidance-button" >Chat with a Counselor Now </button>
          </Link>
         
        </div>
        <div className="career-guidance-image-container">
          <img className="career-guidance-image" src="https://idreamcareer.com/wp-content/uploads/2022/12/career-counselling.webp" alt="career counseling" />
        </div>
      </div>
    </section>
  );
}

export default MId_0;
