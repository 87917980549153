import React, { useState, useEffect } from 'react';
import '../css/Homepage.css';
import vect from '../Media/123.png';
import Homepage1 from './Hompage1';
import Homepage2 from './Homepage2';

const Homepage = () => {
  const [dynamicTextIndex, setDynamicTextIndex] = useState(0);
  const dynamicTexts = [  'WEBSITE DEVELOPMENT FOR YOU',  'GRAPHICS DESIGNING FOR YOU',  'VIDEO EDITING FOR YOU',  'CONTENT CREATION FOR YOU',  'SOCIAL MEDIA HANDLING FOR YOU'];


  useEffect(() => {
    const interval = setInterval(() => {
      setDynamicTextIndex(prevIndex =>
        prevIndex === dynamicTexts.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change text every 3 seconds

    return () => clearInterval(interval); // Clean up the interval
  }, []);

  // Function to dynamically change text color based on the index
  const getTextColor = () => {
    const index = dynamicTextIndex % 5; // Ensure index is within range
    switch (index) {
      case 0:
        return 'blue'; // Example color
      case 1:
        return 'green'; // Example color  
      case 2:
        return 'red'; // Example color
      case 3:
        return 'purple'; // Example color
      case 4:
        return 'orange'; // Example color
      default:
        return 'black'; // Default color
    }
  };

  return (
    <>
      {/* <div className="homepage-container">
      <div className="left-content">
        <h1>Welcome to Mindmap Digital Solutions</h1>
        <h1>WE WILL DO : </h1>
        <h2 className="text-animation" style={{ color: getTextColor() }}>{dynamicTexts[dynamicTextIndex]}</h2>
        <button>Get Started</button>
      </div>
      <div className="right-content">
        <div className="banner">
          <img src={vect} alt="Aesthetic" />
        </div>
      </div>
    </div> */}
    <div> 
      <Homepage1/>
    </div>
    {/* <div>
      <Homepage2/>
    </div> */}
    </>

  );
};

export default Homepage;
