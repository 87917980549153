import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hompage from './components/Hompage';
import NotFound from './components/NotFound';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import WebsiteDevelopment from './components/WebsiteDevelopment';
import ContactPage from './components/ContactPage';
import PerformanceMarketing from './components/PerformanceMarketing';
import VideoEditing from './components/VideoEditing';
import GraphicsDesign from './components/GraphicsDesign';
import BusinessConsultation from './components/BusinessConsultation';
import LeadGeneration from './components/LeadGeneration';
import Team from './components/Team';
import Whatsapp from './components/Whatsapp';

// ScrollToTop component
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

// App component
export default function App() {
  return (
    <>
      <div>
        <BrowserRouter>
          <ScrollToTop /> {/* Add the ScrollToTop component */}
          <div>
            <Navbar />
            <Whatsapp/>
            <Routes>
              <Route exact path="/" element={<Hompage />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/services" element={<Services />} />
              <Route path="/WebsiteDevelopment" element={<WebsiteDevelopment />} />
              <Route path="/contactpage" element={<ContactPage />} />
              <Route path="/performance_marketing" element={<PerformanceMarketing />} />
              <Route path="/video_editing" element={<VideoEditing />} />
              <Route path="/graphics_design" element={<GraphicsDesign />} />
              <Route path="/business_consultation" element={<BusinessConsultation />} />
              <Route path="/lead_generation" element={<LeadGeneration />} />
              <Route path="/team_members" element={<Team />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
          </div>
        </BrowserRouter>
      </div>
    </>
  );
}
